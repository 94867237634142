import React, { useState } from 'react'
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import axios from "axios";
import Header from '../components/Header'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import icon2 from '../assets/img/icon-2.png'
import icon3 from '../assets/img/icon-3.png'

const Donate = () => {
  const [contributiongoal, setcontributiongoal] = useState('')
  const [contributiongoalcheckedItems, setContributiongoalCheckedItems] = useState({
    GeneralSupportFund: false,
    OrphansEducationalSupportFund: false,
    Covid19ReliefProgram: false,
    FoodandShealterRelief: false,
    ChristmasRelief: false,
    AdoptanOrphan: false,
  });
  const [adoptanOrphanPaymentCommitment, setAdoptanOrphanPaymentCommitment] = useState({
    AnnualPayment: false,
    SemiAnnualpayment: false,
    QuarterlyPayments: false,
  });
  const [childsponsorNeeds, setChildsponsorNeeds] = useState({
    mostInNeed: false,
    letMeDecide: false,
  });
  const [gender, setGender] = useState({
    Male: false,
    Female: false,
  });
  const [ageRange, setAgeRange] = useState('');
  const [donor, setDonor] = useState('');
  const [honoreeName, setHonoreeName] = useState('');
  const [message, setMessage] = useState("");
  const [amount, setAmount] = useState(10.00);
  const [donorMessage, setDonorMessage] = useState("");
  const [orderID, setOrderID] = useState('')
  const initialOptions = {
    "client-id": process.env.REACT_APP_PAYPALCLIENTID,
    //"enable-funding": "paylater,venmo",
    "data-sdk-integration-source": "integrationbuilder_sc",
    "currency": "USD",
    "intent": "capture",
    "debug": true,
  };

  const BASE_URL = 'https://api-m.sandbox.paypal.com'; // Use 'https://api-m.paypal.com' for live environment

  const handleContributiongoalCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setContributiongoalCheckedItems({
      ...contributiongoalcheckedItems,
      [name]: checked,
    });
  };
  const handleAdoptanOrphanPaymentCommitmentChange = (event) => {
    const { name, value } = event.target;

    // Create a new object with all values set to false
    const newState = {};
    Object.keys(adoptanOrphanPaymentCommitment).forEach(key => {
      newState[key] = false;
    });

    // Set the selected radio button's value to true
    newState[name] = value;

    // Update the state
    setAdoptanOrphanPaymentCommitment(newState);
  };
  const handleChildsponsorNeedsChange = (event) => {
    const { name, value } = event.target;

    // Create a new object with all values set to false
    const newState = {};
    Object.keys(childsponsorNeeds).forEach(key => {
      newState[key] = false;
    });

    // Set the selected radio button's value to true
    newState[name] = value;
    setChildsponsorNeeds(newState);
  };
  const handleGenderChange = (event) => {
    const { name, value } = event.target;

    // Create a new object with all values set to false
    const newState = {};
    Object.keys(gender).forEach(key => {
      newState[key] = false;
    });

    // Set the selected radio button's value to true
    newState[name] = value;
    setGender(newState);
  };
  const handleAgeRangeChange = (event) => {
    setAgeRange(event.target.value);
  };
  const handleDonorChange = (event) => {
    setDonor(event.target.value);
  };
  const handleHonoreeNameChange = (event) => {
    setHonoreeName(event.target.value);
  };
  const handleDonorMessageChange = (event) => {
    setDonorMessage(event.target.value);
  };

  const getAccessToken = async () => {
    const response = await axios({
      url: `${BASE_URL}/v1/oauth2/token`,
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Accept-Language': 'en_US',
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      auth: {
        username: process.env.REACT_APP_PAYPALCLIENTID,
        password: process.env.REACT_APP_PAYPALCLIENTSECRET,
      },
      params: {
        grant_type: 'client_credentials'
      }
    });

    return response.data.access_token;
  }

  const createOrder = async () => {
    const accessToken = await getAccessToken();

    const orderData = {
      intent: 'CAPTURE',
      purchase_units: [{
        amount: {
          currency_code: 'USD',
          value: amount
        }
      }]
    };

    const response = await axios({
      url: `${BASE_URL}/v2/checkout/orders`,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      },
      data: orderData
    });

    return response.data;
  }

  // Function to capture an order
  const captureOrder = async (orderId) => {
    const accessToken = await getAccessToken();

    const response = await axios({
      url: `${BASE_URL}/v2/checkout/orders/${orderId}/capture`,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      }
    });

    console.log('capture response.data', response.data)
    return response.data;
  }

  return (
    <>
      {/* <div id="spinner" className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <div className="spinner-grow text-primary" role="status"></div>
      </div> */}
      <Navbar />
      <Header title="Donate Now" />

      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: '500px' }}>
            <div className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 mb-3"></div>
            <h1 className="display-6 mb-5">Ways you can donate</h1>
          </div>
          <div className="row g-4 justify-content-center mt-3">
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
              <div className="service-item bg-white text-center h-100 p-4 p-xl-5">
                <img className="img-fluid mb-4" src={icon2} alt="" />
                <h4 className="mb-1">Via Mail</h4>
                <p>Uganda AIDS Orphan Children Foundation, Inc.</p>
                <p>P.O. Box 64485. Los Angeles, CA 9006</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
              <div className="service-item bg-white text-center h-100 p-4 p-xl-5">
                <img className="img-fluid mb-4" src={icon3} alt="" />
                <h4 className="mb-1">Online</h4>
                <p>Make a tax deductible donation.</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
              <div className="service-item bg-white text-center h-100 p-4 p-xl-5">
                <img className="img-fluid mb-4" src={icon3} alt="" />
                <h4 className="mb-1">Over the Phone</h4>
                <p>It's easy to donate offline too.</p>
                <p>Tel: (310) 479-8396</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
              <div className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 mb-3">Donate Now</div>
              <h1 className="display-6 mb-5">Less Overhead, More Giving.</h1>
              <p className="mb-0">Over 97% of donations go directly to caring for the children, so you know you are making a real difference.</p>
            </div>
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
              <div className="h-100 bg-secondary p-5">
                <form>
                  <div className="row g-3">
                    <div className="col-12">
                      <label htmlFor="name">I wish to contribute to one or more of the following:</label>
                      <div className="form-check">
                        <input checked={contributiongoalcheckedItems.GeneralSupportFund}
                          onChange={handleContributiongoalCheckboxChange} className="form-check-input" type="checkbox" id="GeneralSupportFund" name="GeneralSupportFund" />
                        <label className="form-check-label" htmlFor="GeneralSupportFund">
                          General Support Fund
                        </label>
                      </div>
                      <div className="form-check">
                        <input checked={contributiongoalcheckedItems.OrphansEducationalSupportFund}
                          onChange={handleContributiongoalCheckboxChange} className="form-check-input" type="checkbox" id="OrphansEducationalSupportFund" name="OrphansEducationalSupportFund" />
                        <label className="form-check-label" htmlFor="OrphansEducationalSupportFund">
                          Orphan's Educational Support Fund/Transition
                        </label>
                      </div>
                      <div className="form-check">
                        <input checked={contributiongoalcheckedItems.Covid19ReliefProgram}
                          onChange={handleContributiongoalCheckboxChange} className="form-check-input" type="checkbox" id="Covid19ReliefProgram" name="Covid19ReliefProgram" />
                        <label className="form-check-label" htmlFor="Covid19ReliefProgram">
                          Covid 19 Relief Program
                        </label>
                      </div>
                      <div className="form-check">
                        <input checked={contributiongoalcheckedItems.FoodandShealterRelief}
                          onChange={handleContributiongoalCheckboxChange} className="form-check-input" type="checkbox" id="FoodandShealterRelief" name="FoodandShealterRelief" />
                        <label className="form-check-label" htmlFor="FoodandShealterRelief">
                          Food and Shelter Relief
                        </label>
                      </div>
                      <div className="form-check">
                        <input checked={contributiongoalcheckedItems.ChristmasRelief}
                          onChange={handleContributiongoalCheckboxChange} className="form-check-input" type="checkbox" id="ChristmasRelief" name="ChristmasRelief" />
                        <label className="form-check-label" htmlFor="ChristmasRelief">
                          Christmas Relief
                        </label>
                      </div>
                      <div className="form-check">
                        <input checked={contributiongoalcheckedItems.AdoptanOrphan}
                          onChange={(e) => {
                            handleContributiongoalCheckboxChange(e)
                            if (contributiongoal === 'Adopt-an-Orphan') {
                              setcontributiongoal('')
                            } else {
                              setcontributiongoal('Adopt-an-Orphan')
                            }
                          }} className="form-check-input" type="checkbox" id="AdoptanOrphan" name="AdoptanOrphan" />
                        <label className="form-check-label" htmlFor="AdoptanOrphan">
                          Adopt-an-Orphan ($350 a year)
                        </label>
                      </div>
                    </div>
                    {contributiongoal === 'Adopt-an-Orphan' && (
                      <>
                        <div className='col-12'>
                          <label htmlFor="name1">I will meet my commitment of $350 with*:</label>
                          <div className="form-check">
                            <input checked={adoptanOrphanPaymentCommitment.AnnualPayment}
                              onChange={(e) => {
                                handleAdoptanOrphanPaymentCommitmentChange(e)
                                setAmount(350.00)
                              }} className="form-check-input" type="radio" name="AnnualPayment" id="AnnualPayment" />
                            <label className="form-check-label" htmlFor="AnnualPayment">
                              Annual Payment ($350.00)
                            </label>
                          </div>
                          <div className="form-check">
                            <input checked={adoptanOrphanPaymentCommitment.SemiAnnualpayment}
                              onChange={(e) => {
                                handleAdoptanOrphanPaymentCommitmentChange(e)
                                setAmount(175.00)
                              }} className="form-check-input" type="radio" name="SemiAnnualpayment" id="SemiAnnualpayment" />
                            <label className="form-check-label" htmlFor="SemiAnnualpayment">
                              Semi-Annual payment ($175)
                            </label>
                          </div>
                          <div className="form-check">
                            <input checked={adoptanOrphanPaymentCommitment.QuarterlyPayments}
                              onChange={(e) => {
                                handleAdoptanOrphanPaymentCommitmentChange(e)
                                setAmount(87.50)
                              }} className="form-check-input" type="radio" name="QuarterlyPayments" id="QuarterlyPayments" />
                            <label className="form-check-label" htmlFor="QuarterlyPayments">
                              Quarterly Payments ($87.50)
                            </label>
                          </div>
                        </div>
                        <div className='col-12'>
                          <label htmlFor="name1">I will sponsor a child who is*:</label>
                          <div className="form-check">
                            <input checked={childsponsorNeeds.mostInNeed}
                              onChange={(e) => {
                                handleChildsponsorNeedsChange(e)
                              }} className="form-check-input" type="radio" name="mostInNeed" id="mostInNeed" />
                            <label className="form-check-label" htmlFor="mostInNeed">
                              Most in need
                            </label>
                          </div>
                          <div className="form-check">
                            <input checked={childsponsorNeeds.letMeDecide}
                              onChange={(e) => {
                                handleChildsponsorNeedsChange(e)
                              }} className="form-check-input" type="radio" name="letMeDecide" id="letMeDecide" />
                            <label className="form-check-label" htmlFor="letMeDecide">
                              Let me choose
                            </label>
                          </div>
                        </div>
                        {childsponsorNeeds.letMeDecide && (
                          <>
                            <div className='col-12'>
                              <label htmlFor="name1">Gender:</label>
                              <div className="form-check">
                                <input checked={gender.Male}
                                  onChange={(e) => {
                                    handleGenderChange(e)
                                  }} className="form-check-input" type="radio" name="Male" id="Male" />
                                <label className="form-check-label" htmlFor="Male">
                                  Male
                                </label>
                              </div>
                              <div className="form-check">
                                <input checked={gender.Female}
                                  onChange={(e) => {
                                    handleGenderChange(e)
                                  }} className="form-check-input" type="radio" name="Female" id="Female" />
                                <label className="form-check-label" htmlFor="Female">
                                  Female
                                </label>
                              </div>
                            </div>
                            <div className='col-12'>
                              <select value={ageRange} onChange={(e) => {
                                handleAgeRangeChange(e)
                              }} className="form-select" aria-label="Default select example">
                                <option selected>Age Range:</option>
                                <option value='Age4to10'>Age 4 - 10</option>
                                <option value='Age11to14'>Age 11 - 14</option>
                                <option value='Age15to18'>Age 15 - 18</option>
                              </select>
                            </div>
                          </>
                        )
                        }

                      </>
                    )}
                    <div className="col-12">
                      <div className="row g-3">
                        <div className="col-lg-6 col-md-6">
                          <select value={donor} onChange={(e) => {
                            handleDonorChange(e)
                          }} className="form-select form-select-lg" aria-label="Default select example" required>
                            <option defaultValue>I’m donating in the name of:</option>
                            <option value="Myself">Myself</option>
                            <option value="Organization">An organization</option>
                            <option value="SomeoneElse">Someone else</option>
                          </select>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="form-floating">
                            <input value={honoreeName} onChange={(e) => {
                              handleHonoreeNameChange(e)
                            }} type="text" className="form-control bg-light border-0" id="name" placeholder="Your Name" />
                            <label htmlFor="name">Organization\ Honoree name: (optional)</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                        <textarea value={donorMessage} onChange={(e) => {
                          handleDonorMessageChange(e)
                        }} className="form-control" placeholder="Leave a message here" id="message" style={{ height: '100px' }}></textarea>
                        <label htmlFor="message">Leave us a comment</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <PayPalScriptProvider options={initialOptions}>
                        <PayPalButtons
                          style={{
                            shape: "rect",
                            //color:'blue' change the default color of the buttons
                            layout: "vertical", //default value. Can be changed to horizontal
                          }}
                          createOrder={async () => {
                            try {
                              const orderData = await createOrder()
                            if (orderData.id) {
                              setOrderID(orderData.id)
                              return orderData.id;
                            } else {
                              const errorDetail = orderData?.details?.[0];
                              const errorMessage = errorDetail
                                ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
                                : JSON.stringify(orderData);
                              setMessage(`Could not initiate PayPal Checkout, try again...${errorMessage}`);
                              // throw new Error(errorMessage);
                            }
                            } catch (error) {
                              setMessage(`Transaction Failed, try again`);
                            }
                            
                          }}
                          onApprove={async () => {
                            try {
                              const orderData = await captureOrder(orderID);
                            const errorDetail = orderData?.details?.[0];

                            if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
                              // (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                              // recoverable state, per https://developer.paypal.com/docs/checkout/standard/customize/handle-funding-failures/
                              setMessage(errorDetail.description);
                            } else if (errorDetail) {
                              setMessage(errorDetail.description);
                              // (2) Other non-recoverable errors -> Show a failure message
                              //throw new Error(`${errorDetail.description} (${orderData.debug_id})`);
                            } else if (!orderData.purchase_units) {
                              setMessage(`invalid order data ${JSON.stringify(orderData)}`);
                              //throw new Error(JSON.stringify(orderData));
                            } else {
                              // (3) Successful transaction -> Show confirmation or thank you message
                              // Or go to another URL:  actions.redirect('thank_you.html');
                              const transaction =
                                orderData?.purchase_units?.[0]?.payments?.captures?.[0] ||
                                orderData?.purchase_units?.[0]?.payments?.authorizations?.[0];
                              // resultMessage(
                              //   `Transaction ${transaction.status}: ${transaction.id}<br><br>See console for all available details`,
                              // );

                              setMessage(`Transaction ${transaction.status}: ${transaction.id}`);
                              // console.log(
                              //   "Capture result",
                              //   orderData,
                              //   JSON.stringify(orderData, null, 2),
                              // );
                              // send email
                              let contrigoals, dt1;
                              if (childsponsorNeeds.letMeDecide) {
                                dt1 = `Gender:<br /> ${JSON.stringify(gender)} <br /> Age Range:<br /> ${JSON.stringify(ageRange)}`
                              }
                              if (contributiongoal === 'Adopt-an-Orphan') {
                                contrigoals = `Transaction:<br /> ${transaction.status}: ${transaction.id} <br /> Payment Commitment <br /> ${JSON.stringify(adoptanOrphanPaymentCommitment)} <br />
                                I will sponsor a child who is: <br /> ${JSON.stringify(childsponsorNeeds)} <br /> ${dt1}`
                              }
                              const to = 'contactuaocf@gmail.com'
                              const subject = 'Donation Information'
                              const body = `I wish to contribute to one or more of the following: <br /> ${JSON.stringify(contributiongoalcheckedItems)} 
                                <br /> ${contrigoals} <br /> I’m donating in the name of: <br /> ${JSON.stringify(donor)} 
                                <br /> Organization / Honoree name:<br /> ${JSON.stringify(honoreeName)} <br /> Comment:<br /> ${JSON.stringify(donorMessage)}`
                              const mailtoLink = `mailto:${to}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

                              window.location.href = mailtoLink;
                            }
                            } catch (error) {
                              console.log('capture error', error)
                              setMessage(`Transaction Failed, try again`);
                            }
                            
                          }}
                        />
                      </PayPalScriptProvider>
                      <p>{message}</p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  )
}

export default Donate

