import React, { useState } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Home from './pages/Home';
import Error from './pages/Error';
import About from './pages/About';
import ChildlineNews from './pages/ChildlineNews';
import GetInvolved from './pages/GetInvolved';
import Contact from './pages/Contact';
import Donate from './pages/Donate';
import Gallery from './pages/Gallery';

const App = () => {
  const [data, setdata] = useState([
    {
      path: "/",
      element: <Home />,
      errorElement: <Error />,
    },
    {
      path: "/about",
      element: <About />,
      errorElement: <Error />,
    },
    {
      path: "/news",
      element: <ChildlineNews />,
      errorElement: <Error />,
    },
    {
      path: "/get-involved",
      element: <GetInvolved />,
      errorElement: <Error />,
    },
    {
      path: "/contact",
      element: <Contact />,
      errorElement: <Error />,
    },
    {
      path: "/donate",
      element: <Donate />,
      errorElement: <Error />,
    },
    {
      path: "/gallery",
      element: <Gallery />,
      errorElement: <Error />,
    },
  ])

  const router = createBrowserRouter(data);

  return (
    <RouterProvider router={router} />
  );
}

export default App;
