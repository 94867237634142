import React from 'react'
import { useRouteError, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import Navbar from '../components/Navbar'
import Header from '../components/Header'
import Footer from '../components/Footer';

const Error = () => {
  const error = useRouteError();
  let navigate = useNavigate();
  return (
    <>
    <Helmet>
    <meta charSet="utf-8" />
    <title>UAOCF</title>
    <link rel="canonical" href="https://orphansuganda.com/404" />
    <meta property="og:title" content="Error page UAOCF" />
    <meta property="og:description"
      content="UAOCF is a non-profit charitable organization that aims to provide care to vulnerable and orphaned  in  Uganda." />
    <meta property="og:image"
      content="%PUBLIC_URL%/vendor/img/UAOCF-logo.webp" />
    <meta property="og:url" content="https://orphansuganda.com/404" />
    <meta name="keywords" content="UAOCF" />
  </Helmet>

      {/* <div id="spinner" className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <div className="spinner-grow text-primary" role="status"></div>
    </div> */}

    <Navbar />

    <Header title={`${error.status} Error`} />

    <div className="container-xxl py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container text-center">
            <div className="row justify-content-center">
                <div className="col-lg-6">
                    <i className="bi bi-exclamation-triangle display-1 text-primary"></i>
                    <h1 className="display-1">{`${error.status} Error`}</h1>
                    <h1 className="mb-4">{error.statusText || error.message}</h1>
                    <p className="mb-4">{error.data}</p>
                    <a onClick={()=> navigate("/")} className="btn btn-outline-primary py-2 px-3" href="#">
                        Go Back To Home
                        <div className="d-inline-flex btn-sm-square bg-primary text-white rounded-circle ms-2">
                            <i className="fa fa-arrow-right"></i>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>

    <Footer />

    </>
  )
}

export default Error
