import React from 'react'
import Navbar from '../components/Navbar'
import Header from '../components/Header'
import Footer from '../components/Footer'
import img1 from '../assets/img/website/img1.jpg'
import img2 from '../assets/img/website/img2.jpg'
import img3 from '../assets/img/website/img3.jpg'
import img4 from '../assets/img/website/img4.jpg'
import img5 from '../assets/img/website/img5.jpg'
import img6 from '../assets/img/website/img6.jpg'
import img7 from '../assets/img/website/img7.jpg'
import img8 from '../assets/img/website/img8.jpg'
import img9 from '../assets/img/website/img9.jpg'
import img10 from '../assets/img/website/img10.jpg'
import img11 from '../assets/img/website/img11.jpg'
import img12 from '../assets/img/website/img12.jpg'

const Gallery = () => {
  return (
    <>
    <Navbar />
    <Header title="Photo Gallery" />
    <div class="container-xxl py-5">
        <div class="container">
          <div class="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: 600 }}>
            <h1 class="mb-3">Our memories</h1>
            <p>Welcome to our photo gallery, where you can browse through captivating moments captured during various events, activities, and special occasions at our foundation</p>
          </div>
          <div class="row g-4">
            <div class="col-lg-4 col-md-12 mb-4 mb-lg-0">
              <img
                src={img1}
                class="w-100 shadow-1-strong rounded mb-4"
                alt="Boat on Calm Water"
              />

              <img
                src={img2}
                class="w-100 shadow-1-strong rounded mb-4"
                alt="Wintry Mountain Landscape"
              />
            </div>

            <div class="col-lg-4 mb-4 mb-lg-0">
              <img
                src={img3}
                class="w-100 shadow-1-strong rounded mb-4"
                alt="Mountains in the Clouds"
              />

              <img
                src={img4}
                class="w-100 shadow-1-strong rounded mb-4"
                alt="Boat on Calm Water"
              />
            </div>

            <div class="col-lg-4 mb-4 mb-lg-0">
              <img
                src={img5}
                class="w-100 shadow-1-strong rounded mb-4"
                alt="Waves at Sea"
              />

              <img
                src={img6}
                class="w-100 shadow-1-strong rounded mb-4"
                alt="Yosemite National Park"
              />
            </div>
            <div class="col-lg-4 mb-4 mb-lg-0">
              <img
                src={img7}
                class="w-100 shadow-1-strong rounded mb-4"
                alt="Waves at Sea"
              />

              <img
                src={img8}
                class="w-100 shadow-1-strong rounded mb-4"
                alt="Yosemite National Park"
              />
            </div>
            <div class="col-lg-4 mb-4 mb-lg-0">
              <img
                src={img9}
                class="w-100 shadow-1-strong rounded mb-4"
                alt="Waves at Sea"
              />

              <img
                src={img10}
                class="w-100 shadow-1-strong rounded mb-4"
                alt="Yosemite National Park"
              />
            </div>
            <div class="col-lg-4 mb-4 mb-lg-0">
              <img
                src={img11}
                class="w-100 shadow-1-strong rounded mb-4"
                alt="Waves at Sea"
              />

              <img
                src={img12}
                class="w-100 shadow-1-strong rounded mb-4"
                alt="Yosemite National Park"
              />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  )
}

export default Gallery
