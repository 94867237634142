import React from 'react'

const TeamModal = ({team}) => {

  return (
    <div className="modal fade" id="teamModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">{team === 'k' ? 'KABALE BASED TEAM' : 'LA BASED TEAM'}</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
          {team === 'k' ? (
            <>
            <p>UAOCF’s prestigious Uganda-based Oversight Committee includes some of the most influential community leaders of our target area of Southwestern Uganda. They are people of passion who share in our mission to educate and provide the emotional and financial support these vulnerable children so desperately need. The Oversight Committee is made up of educators, medical professionals, alumni of our program, and local community leaders, including the Bishop of Kabale Diocese and the former Mayor of Kabale. With the exception of the Program Administrator and his assistant, they are all volunteers who provide their expertise and set the priorities on how to most effectively use our resources, focusing on providing a quality education for our children.</p>
             <ul>
             <li>Bishop Callistus Rubaramira, Chairperson of the Oversight Committee.</li>
             <li>Msgr. Silverio Twinomugisha, Vice Chairperson of the Oversight Committee.</li>
             <li>Mr. Isaac Masiko, Chairperson of the Supervisory Subcommittee.</li>
             <li>Fr. Vincent Kanyonza is a Member of the Selection Subcommittee.</li>
             <li>Mr. Faustino Twayaga, Member of Selection Committee.</li>
             <li>Mr. Silverio Begambagye, Chairman of the Transition Committee</li>
             <li>Dr. Pius Ruhemurana, former Mayor of Kabale and Member of the Supervisory and Transition Committee.</li>
             <li>Ms. Jacinta Tinzaarwa, Chairperson of the Intervention Committee.</li>
             <li>Mr. Evarist Tumwekwase, UAOCF’s Program Administrator.</li>
             <li>Sr. Fausta Twinomugisha, Assistant to Program Administrator.</li>
             </ul>
            </>
          ) : (
            <>
             <p>Rev. Frederick Byaruhanga, Executive Director and Chairman of UAOCF, grew up, was educated and ordained a priest in Uganda before coming to Los Angeles in 1993 for graduate studies at Loyola Marymount and UCLA. Together, with an incredible group of friends and colleagues, Fr. Fred founded UAOCF in 2003 as a collaborative effort with community leaders in Kabale who would become UAOCF's Oversight Committee. Fr. Fred heads a UAOCF Management Team in the Los Angeles area, which is made up of volunteers from different walks of life, including business, medicine, and nursing, teaching, and information technology. The LA based Management Team works closely with the Program Administrator and the Oversight Committee in Uganda.</p>
             <ul>
             <li>Executive Director and Chairman of the Board of Directors: Fr. Fred Byaruhanga.</li>
             <li>Assistant to Executive Director and Chair of the Management Team: Silvia Maron.</li>
             <li>Member of the Board/Advisor to Chair and Grants Manager:  Angeline Jacobs.</li>
             <li>Member of the Board/Secretary: Patricia Wallace.</li>
             <li>Member of the Board/Treasurer: Silvia Maron.</li>
             <li>Member of the Board/Child Line Editor: Sharon Hudson</li>
             <li>Member of the Board:  Jay DeMiranda</li>
             <li>Member of the Board: Jeanne Goestenkors</li>
             <li>Mail Coordinator and Contact Manager: Deanie Weddle</li>
             <li>Event Managers:  Cassie Bull, Lucy Michel</li>
             <li>Assistant to Event Managers: Denise Kerfoot</li>
             <li>Adopt-An-Orphan Manager: Patricia Wallace</li>
             <li>Transition Manager:  Christine Winicki</li>
             <li>Communications Committee: Fr. Fred Byaruhanga, Angeline Jacobs, Silvia Maron, Jeanne Goestenkors, Evelyn Hatt, Rosemary Nani, Patricia Wallace (Chair)  </li>
             </ul>
            </>
          )}
         </div>
        </div>
      </div>
    </div>
  )
}

export default TeamModal
