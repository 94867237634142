import React from 'react'
import Header from '../components/Header'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

const ChildlineNews = () => {
  return (
    <>
    {/* <div id="spinner" class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <div class="spinner-grow text-primary" role="status"></div>
    </div> */}
    <Navbar />
    <Header title="ChildLine News" />
    <Footer />
    </>
  )
}

export default ChildlineNews
