import React, {useState} from 'react'
import Header from '../components/Header'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import about1 from '../assets/img/website/lessons1.jpg'
import about2 from '../assets/img/website/stmaria.jpg'
import team1 from '../assets/img/kabaleteam.webp'
import team2 from '../assets/img/lateam.webp'
import TeamModal from '../components/TeamModal'

const About = () => {
  const [team, setteam] = useState('1')
  return (
    <>
      {/* <div id="spinner" className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <div className="spinner-grow text-primary" role="status"></div>
      </div> */}
      <Navbar />
      <Header title="About us" />
      <div className="container-xxl px-5 py-5 mb-3">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
              <div className="h-100">
                <div className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 mb-3"></div>
                {/* <h1 className="display-6 mb-5">Our Mission</h1> */}
                <div className="bg-light border-bottom border-5 border-primary rounded p-4 mb-4">
                  <p className="text-dark mb-2">UAOCF is a non-profit charitable organization that aims to provide care to vulnerable and orphaned  in  Uganda.</p>
                </div>
                <p>Education is the key to success and at UAOCF we do everything we can to support children from primary school to vocational school & university. Our objective is to identify vulnerable children in Uganda and provide consistent assistance with their education, health care, and daily needs.</p>
                <p>We work primarily in the underserved and rural population of the Kabale, Rukungiri, Kisoro and Kanungu districts. We ensure that compassionate, quality assistance is given to these Ugandan children, with a high standard of care and respect for their individual dignity and well-being throughout their childhood and educational career. We believe in consistent support and provide care for all of our children from the time they are enrolled in school until they graduate.</p>
                <p>UAOCF's aim is to see all orphaned children given the support and education they need to succeed in life.</p>
                
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <div className="position-relative overflow-hidden h-100" style={{ minHeight: '400px' }}>
                <img className="position-absolute w-100 h-100 pt-5 pe-5" src={about1} alt="" style={{ objectFit: 'cover' }} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-light px-5 py-5 mb-5">
        <div className="container">
          <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: '500px' }}>
            <div className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 mb-3"></div>
            <h1 className="display-6 mb-5">Every Child Deserves The Opportunity To Learn</h1>
          </div>
          <div className="row g-4 justify-content-center">
            <div className="col-lg-5 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
              <div className="causes-item d-flex flex-column bg-white border-top border-5 border-primary rounded-top overflow-hidden h-100">
                <div className="text-center p-4 pt-0">
                  <div className="d-inline-block bg-primary text-white rounded-bottom fs-5 pb-1 px-3 mb-4">
                    <small>Mission</small>
                  </div>
                  <h5 className="mb-3">Our mission is to provide assistance for the daily needs, health care, and education of AIDS-orphaned and vulnerable children in four districts of southwestern Uganda.</h5>
                  <p>UAOCF is here to empower communities through increasing education in underserved communities throughout rural Uganda.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
              <div className="causes-item d-flex flex-column bg-white border-top border-5 border-primary rounded-top overflow-hidden h-100">
                <div className="text-center p-4 pt-0">
                  <div className="d-inline-block bg-primary text-white rounded-bottom fs-5 pb-1 px-3 mb-4">
                    <small>Vission</small>
                  </div>
                  <h5 className="mb-3">We believe that strong support systems and quality education are the gateway to success.</h5>
                  <p>We work to see an increase in graduation rates, community development and empower children through education.</p>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl mb-3 px-5 py-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
              <div className="h-100">
                <div className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 mb-3"></div>
                <h1 className="display-6 mb-1">Why We're Here</h1>
                <div className="bg-light border-bottom border-5 border-primary rounded p-4 mb-4">
                  <p className="text-dark mb-2">According to the latest census from Uganda, in our focus area of four districts in southwestern Uganda (Kabale, Kanungu, Kisoro and Rukungiri), nearly 9% of all children are orphans. This is a staggering statistic that translates today into 64,700 orphaned children in this area, of which an estimated 24,000 have been orphaned by AIDS.</p>
                </div>
                <h1 className="display-6 mb-1">Our Committees</h1>
                <p className="mb-5">Our Subcommittees in Uganda are comprised of members of our Oversight Committee, as well as community leaders and alumni of our Transition Program who are committed to giving back. Collectively, they bring their unique experiences and expertise in a more focused way to implement our programs.</p>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <div className="position-relative overflow-hidden h-100" style={{ minHeight: '400px' }}>
                <img className="position-absolute w-100 h-100 pt-5 pe-5" src={about2} alt="" style={{ objectFit: 'cover' }} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: '500px'}}>
            <div className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 mb-3"></div>
            <h1 className="display-6 mb-5">Meet Our Team</h1>
          </div>
          <div className="row g-4 mx-auto text-center">
          <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
            </div>
            <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
              <div onClick={()=> setteam('k')} style={{cursor: 'pointer'}} data-bs-toggle="modal" data-bs-target="#teamModal" className="team-item position-relative rounded overflow-hidden">
                <div className="overflow-hidden">
                  <img className="img-fluid" src={team1} alt="" />
                </div>
                <div className="team-text bg-light text-center p-4">
                  <h5>KABALE BASED TEAM</h5>
                </div>
              </div>
            </div>
            
            <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
              <div onClick={()=> setteam('l')} style={{cursor: 'pointer'}} data-bs-toggle="modal" data-bs-target="#teamModal" className="team-item position-relative rounded overflow-hidden">
                <div className="overflow-hidden">
                  <img className="img-fluid" src={team2} alt="" />
                </div>
                <div className="team-text bg-light text-center p-4">
                  <h5>LA BASED TEAM</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
            </div>
          </div>
        </div>
      </div> */}
      <div className="bg-light px-5 py-5">
        <div className="container">
          <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: '500px' }}>
            <div className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 mb-3"></div>
            <h1 className="display-6 mb-5">Meet Our Team</h1>
          </div>
          <div className="row g-4 justify-content-center">
            <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
            <div onClick={()=> setteam('k')} style={{cursor: 'pointer'}} data-bs-toggle="modal" data-bs-target="#teamModal" className="team-item position-relative rounded overflow-hidden">
                <div className="overflow-hidden">
                  <img className="img-fluid" src={team1} alt="" />
                </div>
                <div className="team-text bg-light text-center p-4">
                  <h5>KABALE BASED TEAM</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
            <div onClick={()=> setteam('l')} style={{cursor: 'pointer'}} data-bs-toggle="modal" data-bs-target="#teamModal" className="team-item position-relative rounded overflow-hidden">
                <div className="overflow-hidden">
                  <img className="img-fluid" src={team2} alt="" />
                </div>
                <div className="team-text bg-light text-center p-4">
                  <h5>LA BASED TEAM</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <TeamModal team={team} />
    </>
  )
}

export default About
