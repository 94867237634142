import React from 'react'
import { Link, NavLink } from "react-router-dom"
import logo from "../assets/img/logo.jpeg";

const Navbar = () => {
  return (
    <div className="container-fluid fixed-top px-0 wow fadeIn" data-wow-delay="0.1s">
        <div className="top-bar text-white-50 row gx-0 align-items-center d-none d-lg-flex">
            <div className="col-lg-6 px-5 text-start">
                <small><i className="fa fa-map-marker-alt me-2"></i>Kampala, Uganda \ Los Angeles CA 90064 </small>
                <small className="ms-4"><i className="fa fa-envelope me-2"></i>contactuaocf@gmail.com</small>
            </div>
            <div className="col-lg-6 px-5 text-end">
                <small>Follow us:</small>
                <a className="text-white-50 ms-3" href="https://x.com/outreachuaocf?s=11&t=0fg03oG4FvRCUzDybHitjQ"><i className="fab fa-twitter"></i></a>
                <a className="text-white-50 ms-3" href="https://www.instagram.com/uaocf23?igsh=MTFvMmNzenI4b3kxeQ%3D%3D&utm_source=qr"><i className="fab fa-instagram"></i></a>
                <a className="text-white-50 ms-3" href="https://www.facebook.com/share/pjozkY81M3STRG97/?mibextid=LQQJ4d"><i className="fab fa-facebook-f"></i></a>
            </div>
        </div>

        <nav className="navbar navbar-expand-lg navbar-dark py-lg-0 px-lg-5 wow fadeIn" data-wow-delay="0.1s">
        <Link to="/" className="navbar-brand ms-4 ms-lg-0">
                <img src={logo} alt="" style={{width: "150px"}} />
            </Link>
            <button type="button" className="navbar-toggler me-4" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarCollapse">
                <div className="navbar-nav ms-auto p-4 p-lg-0">
                    <NavLink className={({ isActive, isPending }) => isActive ? "nav-item nav-link active" : "nav-item nav-link"} to="/">Home</NavLink>
                    <NavLink className={({ isActive, isPending }) => isActive ? "nav-item nav-link active" : "nav-item nav-link"} to="/about">About</NavLink>
                    {/* <NavLink className={({ isActive, isPending }) => isActive ? "nav-item nav-link active" : "nav-item nav-link"} to="/news">ChildLine News</NavLink> */}
                    <NavLink className={({ isActive, isPending }) => isActive ? "nav-item nav-link active" : "nav-item nav-link"} to="/get-involved">Get Involved</NavLink>
                    <NavLink className={({ isActive, isPending }) => isActive ? "nav-item nav-link active" : "nav-item nav-link"} to="/gallery">Gallery</NavLink>
                    <NavLink className={({ isActive, isPending }) => isActive ? "nav-item nav-link active" : "nav-item nav-link"} to="/contact">Contact</NavLink>
                </div>
                <div className="d-lg-flex ms-2">
                    <NavLink className={({ isActive, isPending }) => isActive ? "btn btn-outline-primary py-2 px-3 active" : "btn btn-outline-primary py-2 px-3"} to="/donate">
                        Donate Now
                        <div className="d-inline-flex btn-sm-square bg-white text-primary rounded-circle ms-2">
                            <i className="fa fa-arrow-right"></i>
                        </div>
                    </NavLink>
                </div>
            </div>
        </nav>
    </div>
  )
}

export default Navbar
