import React from 'react'
import { Link } from "react-router-dom";
import Navbar from '../components/Navbar'
import carousel1 from '../assets/img/website/childrenplaying.jpg'
import carousel2 from '../assets/img/website/ChildrenPraisingGod.jpg'
import about1 from '../assets/img/website/children1.jpg'
import donateimg from '../assets/img/website/img4.jpg'
import Footer from '../components/Footer'
import testimonial1 from '../assets/img/person.png'

const Home = () => {
  return (
    <>
    {/* <div id="spinner" class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <div class="spinner-grow text-primary" role="status"></div>
    </div> */}
    
      <Navbar />

      {/* carousel */}
      <div className="container-fluid p-0 mb-5">
        <div id="header-carousel" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img className="w-100" src={carousel1} alt="Image" />
              <div className="carousel-caption">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-lg-7 pt-5">
                      <h1 className="display-4 text-white mb-3 animated slideInDown">Creating Change Through Education</h1>
                      <p className="fs-5 text-white-50 mb-5 animated slideInDown">UAOCF (Uganda AIDS Orphan Children Foundation) is a 501(c) (3) nonprofit organization dedicated to lifting children out of poverty</p>
                      <Link className="btn btn-primary py-2 px-3 animated slideInDown" to="/about">
                        Learn More
                        <div className="d-inline-flex btn-sm-square bg-white text-primary rounded-circle ms-2">
                          <i className="fa fa-arrow-right"></i>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <img className="w-100" src={carousel2} alt="Image" />
              <div className="carousel-caption">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-lg-7 pt-5">
                      <h1 className="display-4 text-white mb-3 animated slideInDown">We are on ground in Uganda</h1>
                      <p className="fs-5 text-white-50 mb-5 animated slideInDown">Providing education and foster care to almost 500 orphaned children. </p>
                      <Link className="btn btn-primary py-2 px-3 animated slideInDown" to="/get-involved">
                        Learn More
                        <div className="d-inline-flex btn-sm-square bg-white text-primary rounded-circle ms-2">
                          <i className="fa fa-arrow-right"></i>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button className="carousel-control-prev" type="button" data-bs-target="#header-carousel"
            data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button className="carousel-control-next" type="button" data-bs-target="#header-carousel"
            data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
      {/* mission */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
              <div className="h-100">
                <div className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 mb-3"></div>
                <h1 className="display-6 mb-5">Our Mission</h1>
                <div className="bg-light border-bottom border-5 border-primary rounded p-4 mb-4">
                  <p className="text-dark mb-2">UAOCF's goal is to empower children in Uganda through education and the elimination of poverty in orphans of the AIDS crisis.</p>
                </div>
                <p className="mb-5">Learn More About Us and What We Do</p>
                <Link className="btn btn-primary py-2 px-3 me-3" to="/about">
                  Learn More
                  <div className="d-inline-flex btn-sm-square bg-white text-primary rounded-circle ms-2">
                    <i className="fa fa-arrow-right"></i>
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <div className="position-relative overflow-hidden h-100" style={{ minHeight: '400px' }}>
                <img className="position-absolute w-100 h-100 pt-5 pe-5" src={about1} alt="" style={{ objectFit: 'cover' }} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* what we do */}
      <div className="bg-light py-5">
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <div className="position-relative overflow-hidden h-100" style={{ minHeight: '400px' }}>
                <iframe className="position-absolute w-100 h-100 pt-5 pe-5" width="560" height="315" src="https://www.youtube.com/embed/J4jIxByiWmo?si=81GoEaz5jWG1jR6E" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
              <div className="h-100">
                <div className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 mb-3"></div>
                <h1 className="display-6 mb-2">What We Do</h1>
                <p className="mb-5">UAOCF oversees the care of orphan children whose parents were victims of the AIDS crisis. We provide school fees, foster home placement, and medical care for children ages 4-18, as well as vocational assistance, medical care, and career guidance.</p>
                <h1 className="display-6 mb-2">The Need</h1>
                <p className="mb-5">There are over 800,000 children in Uganda that have been orphaned due to the AIDS crisis, and of those far too many live in extreme poverty.</p>
                <Link className="btn btn-primary py-2 px-3 me-3" to="/get-involved">
                  Learn More
                  <div className="d-inline-flex btn-sm-square bg-white text-primary rounded-circle ms-2">
                    <i className="fa fa-arrow-right"></i>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* donate */}
      <div className="donate py-5" data-parallax="scroll" data-image-src={carousel2}>
        <div className="container py-5">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
              <div className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 mb-3">Donate Now</div>
              <h1 className="display-6 text-white mb-5">Less Overhead, More Giving.</h1>
              <p className="text-white-50 mb-0">Over 97% of donations go directly to caring for the children, so you know you are making a real difference.</p>
              <Link className="btn btn-primary py-2 px-3 me-3 mt-3" to="/donate">
                  Donate Now
                  <div className="d-inline-flex btn-sm-square bg-white text-primary rounded-circle ms-2">
                    <i className="fa fa-arrow-right"></i>
                  </div>
                </Link>
            </div>
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
            <div className="position-relative overflow-hidden h-100" style={{ minHeight: '400px' }}>
                <img className="position-absolute w-100 h-100 pe-5" src={donateimg} alt="" style={{ objectFit: 'cover' }} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* testimonials */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: '500px', visibility: 'visible', animationDelay: '0.1s', animationName: 'fadeInUp' }}>
            <div className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 mb-3">Testimonials</div>
            <h1 className="display-6 mb-5">What the people say</h1>
          </div>
          <div className="owl-carousel testimonial-carousel wow fadeInUp animated" data-wow-delay="0.1s">
            <div className="testimonial-item text-center">
              <img className="img-fluid bg-light rounded-circle p-2 mx-auto mb-4" src={testimonial1} style={{ width: '100px', height: '100px' }} />
              <div className="testimonial-text rounded text-center p-4">
                <p>Because of your support, I have been able to successfully finish my second year at university doing a Bachelor’s degree in Statistics and Planning.</p>
                <h5 className="mb-1">Ronald M. Intern at Uganda Revenue Authority</h5>
              </div>
            </div>
            <div className="testimonial-item text-center">
              <img className="img-fluid bg-light rounded-circle p-2 mx-auto mb-4" src={testimonial1} style={{ width: '100px', height: '100px' }} />
              <div className="testimonial-text rounded text-center p-4">
                <p>I was dancing in the dark, but you came to me as a spot of life..</p>
                <h5 className="mb-1">Saviours A. Transition to Vocational School</h5>
              </div>
            </div>
            <div className="testimonial-item text-center">
              <img className="img-fluid bg-light rounded-circle p-2 mx-auto mb-4" src={testimonial1} style={{ width: '100px', height: '100px' }} />
              <div className="testimonial-text rounded text-center p-4">
                <p>Thank you for the wonderful work you do. God knows how wonderful you are and I know how wonderful you are.</p>
                <h5 className="mb-1">Donor</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* footer */}
      <Footer />
      {/* <!-- Back to Top --> */}
      <a href="#" className="btn btn-lg btn-primary btn-lg-square back-to-top"><i className="bi bi-arrow-up"></i></a>
    </>
  )
}

export default Home
