import React, { useState } from 'react'
import Header from '../components/Header'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import about1 from '../assets/img/website/children1.jpg'

const Contact = () => {
  const [name, setname] = useState('')
  const [email, setemail] = useState('')
  const [subject, setsubject] = useState('')
  const [message, setmessage] = useState('')

  const handleNameChange = (event) => {
    setname(event.target.value);
  };
  const handleEmailChange = (event) => {
    setemail(event.target.value);
  };
  const handleSubjectChange = (event) => {
    setsubject(event.target.value);
  };
  const handleMessageChange = (event) => {
    setmessage(event.target.value);
  };
  const handleSubmit = async (event) => {
    event.preventDefault()
    console.log('email submit', name, email, subject,message)
    const to = 'contactuaocf@gmail.com'
    const body = `Subject: ${subject} <br /> Name: ${name} <br /> Email: ${email} <br /> Message: ${message}`
    const mailtoLink = `mailto:${to}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

      window.location.href = mailtoLink;
  };

  return (
    <>
      {/* <div id="spinner" className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <div className="spinner-grow text-primary" role="status"></div>
      </div> */}
      <Navbar />
      <Header title="Contact us" />

      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s" style={{ minHeight: '450px' }}>
              <div className="position-relative overflow-hidden h-100" style={{ minHeight: '400px' }}>
                <img className="position-absolute w-100 h-100 pt-5 pe-5" src={about1} alt="" style={{ objectFit: 'cover' }} />
              </div>
            </div>
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
              <div className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 mb-3">Contact Us</div>
              <h1 className="display-6 mb-5">If You Have Any Query, Please Contact Us</h1>
              <form onSubmit={handleSubmit} method="post">
                <div className="row g-3">
                  <div className="col-md-6">
                    <div className="form-floating">
                      <input value={name} onChange={(e) => handleNameChange(e)} type="text" className="form-control" id="name" name="fullname" placeholder="Your Name" required />
                      <label htmlFor="name">Your Name</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-floating">
                      <input value={email} onChange={(e) => handleEmailChange(e)} type="email" className="form-control" id="email" name="email" placeholder="Your Email" required />
                      <label htmlFor="email">Your Email</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-floating">
                      <input value={subject} onChange={(e) => handleSubjectChange(e)}  type="text" className="form-control" id="subject" placeholder="Subject" required/>
                      <label htmlFor="subject">Subject</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-floating">
                      <textarea value={message} onChange={(e) => handleMessageChange(e)}  className="form-control" placeholder="Leave a message here" id="message" style={{ height: '100px' }} required ></textarea>
                      <label htmlFor="message">Message</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <button type='submit' className="btn btn-primary py-2 px-3 me-3">
                      Send Message
                      <div className="d-inline-flex btn-sm-square bg-white text-primary rounded-circle ms-2">
                        <i className="fa fa-arrow-right"></i>
                      </div>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  )
}

export default Contact
