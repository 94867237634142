import React from 'react'
import Header from '../components/Header'
import Navbar from '../components/Navbar'
import icon1 from '../assets/img/icon-1.png'
import icon2 from '../assets/img/icon-2.png'
import icon3 from '../assets/img/icon-3.png'
import Footer from '../components/Footer'

const GetInvolved = () => {
  return (
    <>
    {/* <div id="spinner" class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <div class="spinner-grow text-primary" role="status"></div>
    </div> */}
    <Navbar />
    <Header title="Get Involved" />
    <div class="container-xxl py-5">
        <div class="container">
            <div class="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: '500px'}}>
                <div class="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 mb-3"></div>
                <h1 class="display-6 mb-5">Learn More About What We Do And Get Involved</h1>
            </div>
            <div class="row g-4 justify-content-center">
                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <div class="service-item bg-white text-center h-100 p-4 p-xl-5">
                        <img class="img-fluid mb-4" src={icon1} alt="" />
                        <h4 class="mb-1">Child sponsorship</h4>
                        <p>Financial support will ensure that those orphans living in foster homes have an opportunity for better health, a good education and a chance for a brighter future. Donors to this program may specify their preference for the orphan’s gender and age range. Donors are encouraged to correspond with the child.</p>
                        <p>To adopt a child ($350 a year) or donate any amount, please send a check (payable to UAOCF) to: P.O. Box 64485, Los Angeles, CA 90064. You can also call 310-479-8396, or use PayPal or your credit card to donate.</p>
                        <p>You can also contribute to the sponsorship of a UAOCF child by donating to the General Fund.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                    <div class="service-item bg-white text-center h-100 p-4 p-xl-5">
                        <img class="img-fluid mb-4" src={icon2} alt="" />
                        <h4 class="mb-1">School and parish events</h4>
                        <p>Have your school sponsor a child, like St. Timothy School in West Los Angeles did when their teachers and staff adopted an orphan. This was a great opportunity for them to show their children how to make a difference in the lives of vulnerable children.</p>
                        <p>We know some children who have taken that to heart! Children at St. James School in Torrance; they held a bake sale to help support our orphans. Then there are the kids at St. Mary Star of the Sea in Oceanside, who sponsored a “Loving Tree” to raise money for UAOCF.</p>
                        <p>What amazing human beings they are! And their donations really matter.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div class="service-item bg-white text-center h-100 p-4 p-xl-5">
                        <img class="img-fluid mb-4" src={icon3} alt="" />
                        <h4 class="mb-1">Contribute to our programs</h4>
                        <p>Besides supporting the UAOCF sponsored children, the General Support Fund also provides for the administration of the UAOCF.</p>
                        <p>UAOCF is administered almost entirely by volunteers, which helps us keep a low overhead. Donations will also be used for promotional efforts for fundraising and dissemination of information.</p>
                    </div>
                </div>
            </div>
            <div class="row g-4 justify-content-center mt-3">
                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                    <div class="service-item bg-white text-center h-100 p-4 p-xl-5">
                        <img class="img-fluid mb-4" src={icon2} alt="" />
                        <h4 class="mb-1">Orphans’ Extraordinary Medical Care</h4>
                        <p>General medical care such as vaccinations and treatment for malaria is provided by the Uganda Government. Orphans with special medical needs may require additional support from UAOCF.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div class="service-item bg-white text-center h-100 p-4 p-xl-5">
                        <img class="img-fluid mb-4" src={icon1} alt="" />
                        <h4 class="mb-1">Orphans’ Special Educational Fund</h4>
                        <p>Depending on the school attended by the child, additional funding for tuition may be required. Also funds may be needed for advanced educational or vocational training.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div class="service-item bg-white text-center h-100 p-4 p-xl-5">
                        <img class="img-fluid mb-4" src={icon3} alt="" />
                        <h4 class="mb-1">Volunteer opportunities</h4>
                        <p>The skills and talents of our volunteers are the backbone that strengthens the infrastructure of our organization. Help us make a difference today.</p>
                        <p>For volunteer opportunities, please contact Angie Jacobs or Silvia Maron at uaocf2003@gmail.com or call us at: 310-479-8396</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="bg-light px-5 py-5">
        <div class="container">
          <div class="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: '500px' }}>
            <div class="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 mb-3"></div>
            <h1 class="display-6 mb-5">How we operate</h1>
          </div>
          <div class="row g-4 justify-content-center">
            <div class="col-lg-10 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
            <h5 class="mb-3">Step 1 - Assessment</h5>
            <p>Our Selection Committee continually works with local schools, parishes and communities to identify children most in need of assistance. We then identify appropriate schools, foster families, and programs that would help these children succeed. Once this is complete, these children are ready to be sponsored through our Adopt-A-Child program.</p>
            <h5 class="mb-3">Step 2 - Sponsor Donation</h5>
            <p>Donors to our Adopt-An-Orphan program provide financial support to ensure that these AIDS-orphaned children have basic care, shelter, education and medical care in our four districts in Southwestern Uganda. In collaboration with community leaders in the area, we strive to provide an opportunity to achieve a productive and self-sustaining future to as many as possible of the estimated 24,000 children in this area who have been orphaned by the loss of one or both parents to AIDS. Charitable donations in any amount make this possible.</p>
            <h5 class="mb-3">Step 3 - Education & Support</h5>
            <p>Our mission is to care for these underserved children by providing a quality education to give them the best opportunity for their success in becoming self-sustaining, contributing members of their communities. On-going operating support is needed for the care and education of these AIDS-orphaned children. Depending on the school attended by the child, additional funding for tuition may be required. Also funds may be needed for advanced educational or vocational training.</p>
            <h5 class="mb-3">Step 4 - Transition To Success</h5>
            <p>Our Transition Program offers career guidance to students in the fourth and sixth (final) year of secondary school, and to academically struggling students who might be better served in vocational or technical studies. The Kabale Transition Committee also provides assistance in placing graduates into productive and self-sustaining employment.</p>
            
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default GetInvolved
