import React from 'react'
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="container-fluid bg-dark text-white-50 footer pt-5 wow fadeIn" data-wow-delay="0.1s">
        <div className="container py-5">
            <div className="row g-5">
                <div className="col-lg-3 col-md-6">
                    <h1 className="fw-bold text-primary mb-4">UAO<span className="text-white">CF</span></h1>
                    <p>Creating Change Through Education</p>
                    <p>UAOCF is a registered 501(c)(3) organization.</p>
                    <div className="d-flex pt-2">
                        <a className="btn btn-square me-1" href="https://x.com/outreachuaocf?s=11&t=0fg03oG4FvRCUzDybHitjQ"><i className="fab fa-twitter"></i></a>
                        <a className="btn btn-square me-1" href="https://www.instagram.com/uaocf23?igsh=MTFvMmNzenI4b3kxeQ%3D%3D&utm_source=qr"><i className="fab fa-instagram"></i></a>
                        <a className="btn btn-square me-1" href="https://www.facebook.com/share/pjozkY81M3STRG97/?mibextid=LQQJ4d"><i className="fab fa-facebook-f"></i></a>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <h5 className="text-light mb-4">Address</h5>
                    <p><i className="fa fa-map-marker-alt me-3"></i>P.O. Box 64485, Los Angeles CA 90064</p>
                    <p><i className="fa fa-phone-alt me-3"></i>310-479-8396</p>
                    <p><i className="fa fa-envelope me-3"></i>info@orphansuganda.com</p>
                </div>
                <div className="col-lg-3 col-md-6">
                    <h5 className="text-light mb-4">Quick Links</h5>
                    <Link className="btn btn-link" to="/">Home</Link>
                    <Link className="btn btn-link" to="/about">About Us</Link>
                    <Link className="btn btn-link" to="/gallery">Gallery</Link>
                </div>
                <div className="col-lg-3 col-md-6">
                <h5 className="text-light mb-4">Quick Links</h5>
                    <Link className="btn btn-link" to="/get-involved">Get Involved</Link>
                    <Link className="btn btn-link" to="/contact">Contact Us</Link>
                    <Link className="btn btn-link" to="/donate">Donate</Link>
                </div>
            </div>
        </div>
        <div className="container-fluid copyright">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                        &copy; <Link to="/">UAOCF</Link>, All Right Reserved.
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Footer
